import React from 'react';
import classNames from 'classnames';
import { i18n } from '@ecster/i18n';
import { Logo, TabletOrDesktop, Link } from '@ecster/components';
import './Footer.scss';

interface Props {
    className?: string;
}
export const Footer = ({ className = '', ...rest }: Props) => {
    const classes = classNames({
        'common-page-footer': true,
        [className]: className,
    });

    return (
        <TabletOrDesktop>
            <footer className={classes} {...rest}>
                <div className="copyright ends">
                    &copy; {i18n('footer.copyright')}
                    {new Date().getFullYear()}
                </div>
                <Logo className="footer-logo" id="footer-logo" withName width="140px" height="35px" />
                <div className="links ends">
                    <Link href={i18n('footer.gdpr.url')} target="_blank" iconRight="icon-external-link">
                        {i18n('footer.gdpr.text')}
                    </Link>
                </div>
            </footer>
        </TabletOrDesktop>
    );
};

export default Footer;
