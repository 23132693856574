import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@ecster/i18n';
import { detectDevice, LabelValue } from '@ecster/components';
import { formatAmount } from '../../../common/util/format-amount';

import './Header.scss';
import { renderThisCountry } from '../../common/i18n/renderThisCountry';
import { AccountHolderIcon } from './AccountHolderIcon';

const AmountLeft = ({ amount, stacked }) => (
    <div>
        <LabelValue
            label={i18n('account.summary.left')}
            value={formatAmount(amount < 0 ? 0 : amount, { strip00: true })}
            green
            stacked={stacked}
        />
    </div>
);
AmountLeft.propTypes = { amount: PropTypes.number.isRequired, stacked: PropTypes.bool.isRequired };

const AmountUsed = ({ amount, stacked }) => (
    <div>
        <LabelValue
            label={i18n('account.summary.used')}
            value={formatAmount(amount < 0 ? 0 : amount, { strip00: true })}
            green
            stacked={stacked}
        />
    </div>
);

AmountUsed.propTypes = { amount: PropTypes.number.isRequired, stacked: PropTypes.bool.isRequired };

const AccountLimit = ({ limit, stacked }) => (
    <div>
        <LabelValue
            label={i18n('account.summary.total')}
            value={formatAmount(limit < 0 ? 0 : limit, { strip00: true })}
            green
            stacked={stacked}
        />
    </div>
);
AccountLimit.propTypes = { limit: PropTypes.number.isRequired, stacked: PropTypes.bool.isRequired };

export const Header = ({ account }) => {
    const renderThreeCols = () => {
        const { isMobile } = detectDevice();
        const amountLeft = account.remaining;

        return (
            <div className="account-transactions-header three-cols">
                <AmountUsed amount={account.used} stacked={!isMobile} />
                <AccountLimit limit={account.limit} stacked={!isMobile} />
            </div>
        );
    };

    const renderTwoCols = () => {
        const amountLeft = account.remaining;

        const label1 = (
            <>
                <span>{i18n('account.summary.card-holder-label')} </span>
                <AccountHolderIcon num={1} inline cardHolder="" />
            </>
        );
        const label2 = (
            <>
                <span>{i18n('account.summary.card-holder-label')} </span>
                <AccountHolderIcon num={2} inline cardHolder="" />
            </>
        );

        return (
            <div className="account-transactions-header two-cols">
                <div>
                    <AmountLeft amount={amountLeft} stacked={false} />
                    <AmountUsed amount={account.used} stacked={false} />
                    <AccountLimit limit={account.limit} stacked={false} />
                </div>
                <div>
                    <LabelValue label={label1} value={account.accountHolder[0].name} green />
                    <LabelValue label={label2} value={account.accountHolder[1].name} green />
                </div>
            </div>
        );
    };

    const renderSE = () => {
        return renderThreeCols();
    };

    const renderFI = () => {
        return account.accountHolder?.length > 1 ? renderTwoCols() : renderThreeCols();
    };

    return renderThisCountry({ renderSE, renderFI });
};

Header.propTypes = { account: PropTypes.shape().isRequired };
