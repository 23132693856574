import React from 'react';
import { Link, ResponsivePanel } from '@ecster/components';
import { i18n } from '@ecster/i18n';

// yes: hardcoded swedish is ok, it's a component located under .../SE/
// eslint-disable-next-line react/prop-types
export const LoginHelp = ({ footer }) => (
    <div className="login-help">
        <h1>Behöver du hjälp med att logga in?</h1>

        <h2>Logga in med BankID</h2>

        <p>
            För att logga in på Ecster behöver du ett BankID. Saknar du BankID? Kontakta din bank eller läs mer via länken
            nedan:
        </p>

        <p>
            <Link
                href="http://support.bankid.com"
                target="_blank"
                id="link-support-bankid-com"
                iconRight="icon-external-link"
                rel="noreferrer"
            >
                https://support.bankid.com
            </Link>
        </p>

        <h2>Har du några andra frågor?</h2>

        <p>
            Om du har några frågor eller behöver hjälp kan du besöka vår&nbsp;
            <Link
                href="https://ecster.se/kundservice"
                target="_blank"
                id="link-customer-support-page-link-svse"
                iconRight="icon-external-link"
                rel="noreferrer"
            >
                kundservice-sida
            </Link>
        </p>
        <div>
            <p>Eller ring oss på: {i18n('general.phone-link-087014667', { danger: true })}</p>
        </div>

        <h2>Hantering av personuppgifter</h2>

        <p className="mb-3x">
            Vi vill att du ska känna dig trygg när du lämnar dina personuppgifter till oss. Från den 25 maj 2018 gäller
            den nya dataskyddsförordningen (GDPR). Kortfattat handlar GDPR om att du har rätt att få reda på vilka
            personuppgifter vi har om dig och vad syftet med den informationen är. GDPR ersätter den svenska
            Personuppgiftslagen (PUL). Vill du veta mer om Ecster och GDPR, gå in på:
        </p>

        <p>
            <Link
                href="https://www.ecster.se/om-oss/hantering-av-personuppgifter"
                target="_blank"
                id="link-ecster-se-gdpr"
                iconRight="icon-external-link"
                rel="noreferrer"
            >
                www.ecster.se/om-oss/hantering-av-personuppgifter
            </Link>
        </p>

        {footer}
    </div>
);
