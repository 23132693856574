import { get } from "@ecster/net/v2";

import {
  ACCOUNT_GET_ACCOUNTS_BEGIN,
  ACCOUNT_GET_ACCOUNTS_SUCCESS,
  ACCOUNT_GET_ACCOUNTS_FAILURE,
  ACCOUNT_GET_ACCOUNTS_DISMISS_ERROR,
  CLEAR_GET_ACCOUNTS_STATE,
} from "./constants";
import { GET_ACCOUNTS_URL } from "./urls";
import initialState from "./initialState";
import parseServerResponseStatus from "../../../common/util/parse-server-response-status";
import { reportError } from "../../../common/reportError";

export const getAccounts = (customerId) => async (dispatch) => {
  dispatch({
    type: ACCOUNT_GET_ACCOUNTS_BEGIN,
  });

  try {
    const res = await get(GET_ACCOUNTS_URL(customerId));
    parseServerResponseStatus(res);

    dispatch({
      type: ACCOUNT_GET_ACCOUNTS_SUCCESS,
      data: res.response.accounts,
    });
  } catch (err) {
    reportError(err, "getAccounts");

    dispatch({
      type: ACCOUNT_GET_ACCOUNTS_FAILURE,
      data: {
        error: {
          message: err,
          action: ACCOUNT_GET_ACCOUNTS_FAILURE,
        },
      },
    });
  }
};

export const dismissGetAccountsError = () => ({
  type: ACCOUNT_GET_ACCOUNTS_DISMISS_ERROR,
});

export const clearGetAccountsState = () => ({ type: CLEAR_GET_ACCOUNTS_STATE });

export function reducer(state, action) {
  let accountsActive;
  let accountsTerminated;

  switch (action.type) {
    case ACCOUNT_GET_ACCOUNTS_BEGIN:
      return {
        ...state,
        getAccountsPending: true,
        getAccountsError: null,
        getAccountsIsDone: false,
        getAccountsIsError: false,
      };

    case ACCOUNT_GET_ACCOUNTS_SUCCESS:
      // note: 2020-06: ETAP does not support account.remaining (may change in the future, then remove the map(...) part)
      accountsActive = action.data
        .filter((a) => a.status === "ACTIVE")
        .map((a) =>
          Number.isInteger(a.remaining)
            ? a
            : { ...a, remaining: a.limit - a.used },
        ); // eslint-disable-line
      accountsTerminated = action.data.filter((a) => a.status === "TERMINATED"); // eslint-disable-line

      return {
        ...state,
        accounts: action.data,
        accountsActive,
        accountsTerminated,
        hasZeroAccounts:
          accountsActive.length === 0 && accountsTerminated.length === 0,
        getAccountsPending: false,
        getAccountsError: null,
        getAccountsIsDone: true,
      };

    case ACCOUNT_GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        accounts: initialState.accounts,
        getAccountsPending: false,
        getAccountsError: action.data.error,
        getAccountsIsError: true,
      };

    case ACCOUNT_GET_ACCOUNTS_DISMISS_ERROR:
      return {
        ...state,
        getAccountsError: initialState.getAccountsError,
        getAccountsIsError: false,
      };

    case CLEAR_GET_ACCOUNTS_STATE:
      return {
        ...state,
        accounts: null,
        accountsActive: null,
        accountsTerminated: null,
        hasZeroAccounts: false,
        getAccountsError: initialState.getAccountsError,
        getAccountsIsDone: false,
        getAccountsIsError: false,
      };

    default:
      return state;
  }
}
