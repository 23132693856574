import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { BottomNavigation, BottomMenu } from '../menu/index';
import { renderThisCountry } from '../i18n/renderThisCountry';

import {
    MenuItemOverview,
    MenuItemInvoices,
    HamburgerMenuBottom,
    SubMenuItemCustomerSupport,
    SubMenuItemCustomerProfile,
    SubMenuItemLogout,
    matchURLs,
} from './Links';

import './MobileNavigation.scss';
import Overlay from './Overlay';

const MobileNavigation = ({ hasZeroAccounts, hasHamburgerNotification, hasInvoiceNotification }) => {
    const [showSubMenu, setShowSubMenu] = useState(false);

    const toggleSubMenu = () => {
        setShowSubMenu(!showSubMenu);
    };

    const renderFI = () => {
        const urls = matchURLs();

        return (
            <>
                <Overlay onClick={toggleSubMenu} show={showSubMenu} />
                <BottomNavigation>
                    <Collapsible
                        open={showSubMenu}
                        trigger={null}
                        contentHiddenWhenClosed
                        lazyRender
                        transitionTime={200}
                    >
                        <SubMenuItemCustomerProfile isActive={urls.customerProfileIsActive} />
                        <SubMenuItemCustomerSupport isActive={urls.customerSupportIsActive} />
                        <SubMenuItemLogout activateTabIndex={showSubMenu} />
                    </Collapsible>
                    <BottomMenu>
                        <MenuItemOverview isActive={urls.overviewIsActive} />
                        <HamburgerMenuBottom toggleSubMenu={toggleSubMenu} hasNotification={hasHamburgerNotification} />
                    </BottomMenu>
                </BottomNavigation>
            </>
        );
    };

    const renderSE = () => {
        const urls = matchURLs();
        // const urls = matchURLs(window.location.hash);

        return (
            <>
                <Overlay onClick={toggleSubMenu} show={showSubMenu} />
                <BottomNavigation>
                    <Collapsible
                        open={showSubMenu}
                        trigger={null}
                        contentHiddenWhenClosed
                        lazyRender
                        transitionTime={200}
                    >
                        {!hasZeroAccounts && <SubMenuItemCustomerProfile isActive={urls.customerProfileIsActive} />}
                        <SubMenuItemCustomerSupport isActive={urls.customerSupportIsActive} />
                        <SubMenuItemLogout />
                    </Collapsible>
                    <BottomMenu>
                        <MenuItemOverview isActive={urls.overviewIsActive} />
                        <MenuItemInvoices isActive={urls.invoiceIsActive} hasNotification={hasInvoiceNotification} />
                        <HamburgerMenuBottom toggleSubMenu={toggleSubMenu} />
                    </BottomMenu>
                </BottomNavigation>
            </>
        );
    };

    return renderThisCountry({ renderSE, renderFI });
};

export default withRouter(MobileNavigation);
