import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, TopMenu, TopNavigation } from '../menu/index';
import { renderThisCountry } from '../i18n/renderThisCountry';

import {
    LinkToOverviewWithLogo,
    LinkToOverview,
    LinkToCustomerInvoices,
    HamburgerMenuTop,
    matchURLs,
    SubMenuItemCustomerProfile,
    SubMenuItemCustomerSupport,
    SubMenuItemLogout,
} from './Links';

import './TabletDesktopNavigation.scss';

const TabletDesktopNavigation = ({
    hasZeroAccounts,
    hasHamburgerNotification,
    hasInvoiceNotification,
}) => {
    const [showSubMenu, setShowSubMenu] = useState(false);

    const toggleSubMenu = () => {
        setShowSubMenu(!showSubMenu);
    };

    const onSubMenuKeyup = e => {
        const [down, up, esc, ret] = [40, 38, 27, 13];
        const kc = e.keyCode;

        // menu is closed
        if (!showSubMenu && kc === down) {
            toggleSubMenu();
        }

        // menu is open
        if (showSubMenu && (kc === up || kc === esc)) {
            toggleSubMenu();
        }

        if (kc === ret) {
            toggleSubMenu();
        }
    };

    const closeSubMenu = () => {
        setShowSubMenu(false);
    };

    const urls = matchURLs();

    const renderFI = () => (
        <TopNavigation>
            <TopMenu>
                <div className="top-menu-container">
                    <LinkToOverviewWithLogo />
                    <div className="top-menu-links">
                        <LinkToOverview isActive={urls.overviewIsActive} />
                        <HamburgerMenuTop
                            hasNotification={hasHamburgerNotification}
                            showSubMenu={showSubMenu}
                            toggleSubMenu={toggleSubMenu}
                            onSubMenuKeyup={onSubMenuKeyup}
                        />
                    </div>
                </div>
            </TopMenu>
            <div className="submenu-container">
                <DropdownMenu top show={showSubMenu} requestClose={closeSubMenu}>
                    <SubMenuItemCustomerProfile isActive={urls.customerProfileIsActive} />
                    <SubMenuItemCustomerSupport isActive={urls.customerSupportIsActive} />
                    <SubMenuItemLogout activateTabIndex={showSubMenu} />
                </DropdownMenu>
            </div>
        </TopNavigation>
    );

    const renderSE = () => (
        <TopNavigation>
            <TopMenu>
                <div className="top-menu-container">
                    <LinkToOverviewWithLogo />
                    <div className="top-menu-links">
                        <LinkToOverview isActive={urls.overviewIsActive} />
                        <LinkToCustomerInvoices
                            isActive={urls.invoiceIsActive}
                            hasNotification={hasInvoiceNotification}
                        />
                        <HamburgerMenuTop
                            showSubMenu={showSubMenu}
                            toggleSubMenu={toggleSubMenu}
                            onSubMenuKeyup={onSubMenuKeyup}
                        />
                    </div>
                </div>
            </TopMenu>
            <div className="submenu-container">
                <DropdownMenu top show={showSubMenu} requestClose={closeSubMenu}>
                    {!hasZeroAccounts && (
                        <SubMenuItemCustomerProfile
                            isActive={urls.customerProfileIsActive}
                            activateTabIndex={showSubMenu}
                        />
                    )}
                    <SubMenuItemCustomerSupport
                        isActive={urls.customerSupportIsActive}
                        activateTabIndex={showSubMenu}
                    />
                    <SubMenuItemLogout activateTabIndex={showSubMenu} />
                </DropdownMenu>
            </div>
        </TopNavigation>
    );

    return renderThisCountry({ renderSE, renderFI });
};

TabletDesktopNavigation.propTypes = {
    hasZeroAccounts: PropTypes.bool,
    hasNotification: PropTypes.bool,
};

TabletDesktopNavigation.defaultProps = {
    hasZeroAccounts: false,
    hasNotification: false,
};
export default TabletDesktopNavigation;
