import React from "react";
import PropTypes from "prop-types";
import { i18n } from "@ecster/i18n";
import ColumnSummaryPanel from "../../common/ColumnSummaryPanel";
import { formatAmount } from "../../../common/util/format-amount";

import "./AccountSummary.scss";

export const AccountSummary = ({ account }) => {
  const { remaining, used, limit } = account;

  const hasSurplusBalance = used < -100; // Överskott under 1kr räknas ej som överskott

  const label1 = hasSurplusBalance
    ? i18n("account.terminated-account.label-refundable")
    : i18n("account.summary.used");

  const value1 = hasSurplusBalance
    ? formatAmount(-used, { strip00: true })
    : formatAmount(used, { strip00: true });

  return (
    <ColumnSummaryPanel
      label1={label1}
      value1={value1}
      label2={i18n("account.summary.total")}
      value2={formatAmount(limit < 0 ? 0 : limit, { strip00: true })}
    />
  );
};

AccountSummary.propTypes = {
  account: PropTypes.shape().isRequired,
};
