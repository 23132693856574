import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@ecster/i18n';

import { cardIcon, fileIcon, infoIcon, transactionsIcon, powerIcon } from '@ecster/icons/H15/green';

import { ArrowLink } from '../../common/arrow-link/ArrowLink';

//
// Common SE + FI
//
export const LinkToTransactions = ({ accountRef, onClick }) => (
    <ArrowLink
        onClick={onClick}
        text={i18n('account.links.transactions')}
        icon={transactionsIcon}
        to={`/account/${accountRef}/transactions`}
        id="account-overview-transactions"
    />
);
LinkToTransactions.propTypes = { accountRef: PropTypes.string.isRequired, onClick: PropTypes.func };
LinkToTransactions.defaultProps = { onClick: () => {} };

export const LinkToAccountTerms = ({ accountRef, onClick }) => (
    <ArrowLink
        onClick={onClick}
        text={i18n('account.links.terms')}
        icon={infoIcon}
        to={`/account/${accountRef}/terms`}
        id="account-overview-account-terms"
    />
);
LinkToAccountTerms.propTypes = { accountRef: PropTypes.string.isRequired, onClick: PropTypes.func };
LinkToAccountTerms.defaultProps = { onClick: () => {} };

//
// SE only
//
export const LinkToAccountInvoice = ({ accountRef, onClick }) => (
    <ArrowLink
        onClick={onClick}
        text={i18n('account.links.account-invoice')}
        icon={fileIcon}
        to={`/account/${accountRef}/invoice`}
        id="account-overview-monthly-invoice"
    />
);
LinkToAccountInvoice.propTypes = { accountRef: PropTypes.string.isRequired, onClick: PropTypes.func };
LinkToAccountInvoice.defaultProps = { onClick: () => {} };export const LinkToManageCards = ({ accountRef, onClick }) => (
    <ArrowLink
        onClick={onClick}
        text={i18n('account.links.manage-cards')}
        icon={cardIcon}
        to={`/card/${accountRef}`}
        id="account-overview-manage-cards"
    />
);
LinkToManageCards.propTypes = {
    accountRef: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
LinkToManageCards.defaultProps = { badge: null, onClick: () => {} };

//
// FI only
//
export const LinkToTransferMoney = ({ accountRef, onClick }) => (
    <ArrowLink
        onClick={onClick}
        text={i18n('account.links.transfer-money')}
        icon={powerIcon}
        to={`/account/${accountRef}/transfer-money`}
        id="account-overview-transfer-money"
    />
);
LinkToTransferMoney.propTypes = { accountRef: PropTypes.string.isRequired, onClick: PropTypes.func };
LinkToTransferMoney.defaultProps = { onClick: () => {} };

export const LinkToAccountInvoices = ({ accountRef, badge, onClick }) => (
    <ArrowLink
        onClick={onClick}
        text={i18n('account.links.account-invoices')}
        icon={fileIcon}
        to={`/account/${accountRef}/invoices`}
        id="account-overview-monthly-invoices"
        badge={badge}
    />
);
LinkToAccountInvoices.propTypes = {
    accountRef: PropTypes.string.isRequired,
    badge: PropTypes.node,
    onClick: PropTypes.func,
};
LinkToAccountInvoices.defaultProps = { badge: null, onClick: () => {} };
