import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18n } from '@ecster/i18n';
import { IconButton, Link, Mobile, TabletOrDesktop } from '@ecster/components';
import MobileNavigation from '../navigation/MobileNavigation';
import TabletDesktopNavigation from '../navigation/TabletDesktopNavigation';
import MessagePanel from '../MessagePanel';
import { Footer } from '../footer';
import { ComCheck } from '../index';
import { useAuthenticatedPageTemplateState } from './hooks/useAuthenticatedPageTemplateState';

const AuthenticatedSubPageTemplate = ({ className = '', linkTo, header, children }) => {
    const {
        showInvoiceNotification,
        showLoanMenu,
        hasZeroAccounts,
        customerId,
        previousPath,
    } = useAuthenticatedPageTemplateState();

    const history = useHistory();

    const contentRef = useRef();

    useEffect(() => {
        const contentRefCurrent = contentRef.current;
        contentRefCurrent.classList?.add('visible');

        return () => {
            contentRefCurrent?.classList?.remove('visible');
        };
    }, [customerId]);

    const goBack = () => {
        if (!previousPath || previousPath === '/' || previousPath === '/tupas/login') {
            history.push('/account/overview');
        } else {
            history.goBack();
        }
    };

    const classes = classNames({
        'common-authenticated-sub-page': true,
        [className]: className,
    });

    const renderHeader = header && (
        <div className="page-header">
            <div className="page-header-content">
                {linkTo ? (
                    <Link to={linkTo} underline={false} id="sub-page-back-link">
                        <i className="icon-arrow-left" />
                    </Link>
                ) : (
                    <IconButton
                        id="back-button"
                        ariaLabel={i18n('general.go-back')}
                        icon="icon-arrow-left"
                        onClick={() => goBack()}
                    />
                )}
                <h1>{header}</h1>
            </div>
        </div>
    );

    return (
        <>
            <div className={classes}>
                <TabletOrDesktop>
                    <TabletDesktopNavigation
                        showLoanMenu={showLoanMenu}
                        hasZeroAccounts={hasZeroAccounts}
                        hasHamburgerNotification={false}
                        hasInvoiceNotification={showInvoiceNotification}
                    />
                </TabletOrDesktop>
                <div className="page-container">
                    {renderHeader}
                    <div className="page-content" ref={contentRef}>
                        <ComCheck />
                        {children}
                    </div>
                </div>
                <Mobile>
                    <MobileNavigation
                        showLoanMenu={showLoanMenu}
                        hasZeroAccounts={hasZeroAccounts}
                        hasHamburgerNotification={false}
                        hasInvoiceNotification={showInvoiceNotification}
                    />
                </Mobile>
            </div>
            <MessagePanel />
            <Footer />
        </>
    );
};

AuthenticatedSubPageTemplate.propTypes = {
    className: PropTypes.string,
    header: PropTypes.string.isRequired,
    linkTo: PropTypes.string,
    children: PropTypes.node.isRequired,
};

AuthenticatedSubPageTemplate.defaultProps = {
    className: '',
    linkTo: undefined,
};

export default AuthenticatedSubPageTemplate;
