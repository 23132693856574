import React from 'react';
import PropTypes from 'prop-types';
import { Badge, InteractiveElement, Link, Logo } from '@ecster/components';
import classNames from 'classnames';
import { i18n } from '@ecster/i18n';
import { matchPath } from 'react-router-dom';
import { MenuItem, MenuItemText, SubMenuItem } from '../menu';
import {
    SvgIconBubbles,
    SvgIconHamburger,
    SvgIconInvoices,
    SvgIconLoan,
    SvgIconLogout,
    SvgIconOverview,
    SvgIconProfile,
} from '../../../common/images';

// LINKS

export const LinkToOverviewWithLogo = () => (
    <Link to="/account/overview" id="top-nav-logo" white>
        <Logo withName fill="#fff" width="120px" height="32px" />
    </Link>
);
LinkToOverviewWithLogo.propTypes = {};

export const LinkToOverview = ({ isActive }) => (
    <Link
        className={classNames({
            'menu-item--is-active': isActive,
        })}
        to="/account/overview"
        id="nav-account-overview"
        white
    >
        {i18n('navigation.account-overview')}
    </Link>
);
LinkToOverview.propTypes = { isActive: PropTypes.bool.isRequired };

export const LinkToCustomerInvoices = ({ isActive, hasNotification }) => (
    <Link
        className={classNames({
            'menu-item--is-active': isActive,
        })}
        to="/customer/invoices"
        id="nav-invoice-overview"
        white
    >
        <>
            {i18n('navigation.invoices')}
            {hasNotification && <div className="has-notification has-notification--invoice" />}
        </>
    </Link>
);
LinkToCustomerInvoices.propTypes = { isActive: PropTypes.bool.isRequired };

export const LinkToLoanOverview = ({ isActive }) => (
    <Link
        className={classNames({
            'menu-item--is-active': isActive,
        })}
        to="/loan/start"
        id="nav-loan-overview"
        white
    >
        {i18n('navigation.loan')}
    </Link>
);
LinkToLoanOverview.propTypes = { isActive: PropTypes.bool.isRequired };

export const LinkToCustomerProfile = ({ isActive }) => (
    <Link
        className={classNames({
            'menu-item--is-active': isActive,
        })}
        to="/customer/profile"
        id="nav-customer-profile"
        white
    >
        {i18n('navigation.customer-profile')}
    </Link>
);

LinkToCustomerProfile.propTypes = { isActive: PropTypes.bool.isRequired };

export const LinkToCustomerSupport = ({ isActive }) => (
    <Link
        className={classNames({
            'menu-item--is-active': isActive,
        })}
        to="/customer/support"
        id="nav-customer-support"
        white
    >
        {i18n('navigation.customer-support')}
    </Link>
);
LinkToCustomerSupport.propTypes = { isActive: PropTypes.bool.isRequired };

export const LinkToLogout = () => (
    <Link ariaLabel={i18n('navigation.logout')} className="icon-link" to="/authentication/logout" id="nav-logout" white>
        <div className="icon-container">
            <i className="icon-lock" />
        </div>
    </Link>
);
LinkToLogout.propTypes = {};

// HAMBURGER MENUS

export const HamburgerMenuTop = ({ showSubMenu, toggleSubMenu, onSubMenuKeyup, hasNotification }) => (
    <div
        className={classNames({
            'icon-link': true,
            'icon-link-hamburger': true,
            'menu-item--is-active': showSubMenu,
        })}
        onClick={toggleSubMenu}
        onKeyUp={onSubMenuKeyup}
        role="link"
        aria-label={i18n('general.more-menu')}
        tabIndex={0}
    >
        <div className="icon-container">
            {hasNotification && <div className="has-notification" />}
            <i className="icon-menu e-white" />
        </div>
    </div>
);
HamburgerMenuTop.propTypes = {
    showSubMenu: PropTypes.bool.isRequired,
    hasNotification: PropTypes.bool,
    toggleSubMenu: PropTypes.func.isRequired,
    onSubMenuKeyup: PropTypes.func.isRequired,
};
HamburgerMenuTop.defaultProps = { hasNotification: false };

export const HamburgerMenuBottom = ({ toggleSubMenu, hasNotification }) => (
    <InteractiveElement onClick={toggleSubMenu}>
        <div
            className={classNames({
                'menu-item': true,
                active: false,
            })}
        >
            {hasNotification && <div className="has-notification" />}
            <SvgIconHamburger />
            <MenuItemText>{i18n('navigation.more')}</MenuItemText>
        </div>
    </InteractiveElement>
);
HamburgerMenuBottom.propTypes = { toggleSubMenu: PropTypes.func.isRequired, hasNotification: PropTypes.bool };
HamburgerMenuBottom.defaultProps = { hasNotification: false };

// SUBMENU ITEMS

export const SubMenuItemCustomerProfile = ({ isActive, activateTabIndex }) => (
    <SubMenuItem
        id="nav-customer-profile"
        linkTo="/customer/profile"
        active={isActive}
        tabIndex={activateTabIndex ? 0 : -1}
    >
        {i18n('navigation.customer-profile')}
    </SubMenuItem>
);
SubMenuItemCustomerProfile.propTypes = {
    isActive: PropTypes.bool.isRequired,
    activateTabIndex: PropTypes.bool,
};
SubMenuItemCustomerProfile.defaultProps = { activateTabIndex: false };

export const SubMenuItemCustomerSupport = ({ isActive, activateTabIndex }) => (
    <SubMenuItem
        id="nav-customer-support"
        linkTo="/customer/support"
        active={isActive}
        tabIndex={activateTabIndex ? 0 : -1}
    >
        {i18n('navigation.customer-support')}
    </SubMenuItem>
);
SubMenuItemCustomerSupport.propTypes = {
    isActive: PropTypes.bool.isRequired,
    activateTabIndex: PropTypes.bool,
};
SubMenuItemCustomerSupport.defaultProps = { activateTabIndex: false };

export const SubMenuItemLogout = ({ activateTabIndex }) => (
    <SubMenuItem
        id="nav-logout"
        linkTo="/authentication/logout"
        iconClass="icon-lock"
        tabIndex={activateTabIndex ? 0 : -1}
    >
        {i18n('navigation.logout')}
    </SubMenuItem>
);
SubMenuItemLogout.propTypes = { activateTabIndex: PropTypes.bool };
SubMenuItemLogout.defaultProps = { activateTabIndex: false };

// BOTTOM MENU ITEMS

export const MenuItemOverview = ({ isActive }) => (
    <MenuItem id="nav-account-overview" linkTo="/account/overview" active={isActive}>
        <SvgIconOverview />
        <MenuItemText>{i18n('navigation.account-overview')}</MenuItemText>
    </MenuItem>
);
MenuItemOverview.propTypes = { isActive: PropTypes.bool.isRequired };

export const MenuItemInvoices = ({ isActive, hasNotification }) => (
    <MenuItem id="nav-invoice-overview" linkTo="/customer/invoices" active={isActive}>
        {hasNotification && <div className="has-notification has-notification--invoice" />}
        <SvgIconInvoices />
        <MenuItemText>{i18n('navigation.invoices')}</MenuItemText>
    </MenuItem>
);
MenuItemInvoices.propTypes = { isActive: PropTypes.bool.isRequired };

export const MenuItemLoanOverview = ({ isActive }) => (
    <MenuItem id="nav-loan-overview" linkTo="/loan/start" active={isActive}>
        <SvgIconLoan />
        <MenuItemText>{i18n('navigation.loan')}</MenuItemText>
    </MenuItem>
);
MenuItemLoanOverview.propTypes = { isActive: PropTypes.bool.isRequired };

export const MenuItemCustomerSupport = ({ isActive }) => (
    <MenuItem id="nav-customer-support" linkTo="/customer/support" active={isActive}>
        <SvgIconBubbles />
        <MenuItemText>{i18n('navigation.customer-support')}</MenuItemText>
    </MenuItem>
);
MenuItemCustomerSupport.propTypes = { isActive: PropTypes.bool.isRequired };

export const MenuItemCustomerProfile = ({ isActive }) => (
    <MenuItem id="nav-customer-profile" linkTo="/customer/profile" active={isActive}>
        <SvgIconProfile />
        <MenuItemText>{i18n('navigation.customer-profile')}</MenuItemText>
    </MenuItem>
);
MenuItemCustomerProfile.propTypes = { isActive: PropTypes.bool.isRequired };

export const MenuItemLogout = () => (
    <MenuItem id="nav-logout" linkTo="/authentication/logout">
        <SvgIconLogout />
        <MenuItemText>{i18n('navigation.logout')}</MenuItemText>
    </MenuItem>
);
MenuItemLogout.propTypes = {};

export const matchURLs = () => {
    const pathname = window.location.hash.split('#')[1];
    const match = path => !!matchPath(pathname, { path, exact: true });

    return {
        overviewIsActive: match('/account/overview/'),
        invoiceIsActive: match('/customer/invoices/') || match('/customer/v2/invoices/'),
        loanIsActive: match('/loan/start'),
        customerProfileIsActive: match('/customer/profile/'),
        customerSupportIsActive: match('/customer/support/'),
    };
};
