import React, { Component } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import { connect } from "react-redux";
import {
  TabletOrDesktop,
  Mobile,
  Panel,
  PanelContent,
  ResponsivePanel,
  Link,
} from "@ecster/components";
import { i18n } from "@ecster/i18n";
import { NextPaymentPanel } from "./NextPaymentPanel";
import { AccountLinksPanel } from "../account-links/AccountLinksPanel";
import { LatestTransactions } from "../transactions/LatestTransactions";
import { OverviewPanelHeader } from "../../common";

import { isSE } from "../../../common/country";

import {
  getAccountTransactions,
  getAccountCards,
  dismissGetAccountTransactionsError,
  getAccountBillOverview,
  dismissGetAccountBillOverviewError,
} from "../redux/actions";

import "./AccountPanel.scss";
import initialState from "../redux/initialState";
import NotificationPanel from "../../../common/NotificationPanel";
import { EcsterCard } from "../../common/card/EcsterCard";
import { formatAmount } from "../../../common/util/format-amount";
import { formatAccount } from "../../../common/util/format-account";
import { getBillProperties } from "../util";
import { PaymentDialog } from "../PaymentDialog";
import { isValidSurplusAmount } from "../../../common/util/is-valid-surplus-amount";

const defaultFilter = initialState.accountTransactionsFilter;

class AccountPanel extends Component {
  state = {
    hasInactiveCards: false,
    isPaymentDialogOpen: false,
  };

  componentDidMount() {
    const {
      getAccountTransactions,
      getAccountBillOverview,
      getAccountCards,
      user: { id } = {},
      account: { reference } = {},
    } = this.props;

    getAccountTransactions(id, reference, defaultFilter);
    getAccountBillOverview(id, reference);
    if (isSE()) {
      getAccountCards(id, reference);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const { accountCard, extraCards } = this.props;

    if (
      (extraCards &&
        extraCards.filter((card) => card.status === "INACTIVE").length > 0) ||
      (accountCard && accountCard.status === "INACTIVE")
    ) {
      this.setState({ hasInactiveCards: true });
    } else {
      this.setState({ hasInactiveCards: false });
    }
  }

  onStartRefund = (e) => {
    e.preventDefault() && e.stopPropagation();
    this.setState({ isPaymentDialogOpen: true });
  };

  onPaymentDialogRequestClose = () => {
    this.setState({ isPaymentDialogOpen: false });
  };

  render() {
    const {
      className,
      account,
      accountCard,
      bill,
      transactions,
      totalTransactions,
      getAccountTransactionsError,
      dismissGetAccountTransactionsError,
      getAccountBillOverviewError,
      dismissGetAccountBillOverviewError,
    } = this.props;

    const hasCard = accountCard
      ? !!accountCard && accountCard.status !== "ORDERED"
      : false;

    const hasSurplusBalance = account.used < -100; // Överskott under 1kr räknas ej som överskott

    const { hasInactiveCards, isPaymentDialogOpen } = this.state;

    if (!transactions) return null;

    const classes = classNames({
      "account-panel": true,
      [className]: className,
    });

    const billProps = getBillProperties(bill);

    const { limit, used, accountNumber, overDrawn } = account;
    const { brickId } = accountCard || {};
    const remainingCredit = account.remaining;

    // overDrawn is only applicable for FI
    const isOverdrawn = overDrawn || limit - used <= -500 * 100; // compare in "öre"

    const latestTransactions = (
      <LatestTransactions
        transactions={transactions}
        getAccountTransactionsError={getAccountTransactionsError}
        dismissGetAccountTransactionsError={dismissGetAccountTransactionsError}
        totalTransactions={totalTransactions}
        account={account}
      />
    );

    const nextPaymentPanel = (
      <NextPaymentPanel
        bill={bill}
        account={account}
        getAccountBillError={getAccountBillOverviewError}
        dismissGetAccountBillError={dismissGetAccountBillOverviewError}
      />
    );

    const notifications = [];

    if (billProps.amountPaidByCustomer && billProps.hasPaidTooLittle) {
      notifications.push({
        id: 1,
        title: i18n("account.next-payment.notification.paid-less-lab1.header"),
        message: i18n("account.next-payment.notification.paid-less-lab1.text", {
          amount: formatAmount(billProps.lab1, { strip00: true }),
        }),
      });
    }

    if (hasSurplusBalance && isSE()) {
      notifications.push({
        id: 1,
        title: i18n("account.has-surplus.notification.header"),
        message: (
          <>
            <p>{i18n("account.has-surplus.notification.text")}</p>
            <p>
              {isValidSurplusAmount(-account.used) ? (
                <Link
                  underline
                  href="#"
                  blue
                  outline
                  small
                  round
                  onClick={this.onStartRefund}
                >
                  {i18n("account.has-surplus.notification.link-text")}
                </Link>
              ) : (
                <p>
                  {i18n(
                    "account.has-surplus.notification.limit-exceeded-text",
                    { danger: true },
                  )}
                </p>
              )}
            </p>
          </>
        ),
        severity: "info",
      });
    }

    if (isOverdrawn) {
      const amount = formatAmount(used - limit, {
        strip00: true,
      });
      const anum = formatAccount(accountNumber);
      notifications.push({
        id: 2,
        title: i18n("account.terminate.overdrawn.header"),
        message: i18n("account.terminate.overdrawn.info", {
          amount,
          accountNumber: anum,
        }),
      });
    }

    return (
      <Panel paddingAll="small" className={classes}>
        <PanelContent fullWidth>
          <OverviewPanelHeader
            header={account.product.name}
            DISABLE_old_amount1={remainingCredit < 0 ? 0 : remainingCredit}
            DISABLE_old_amount1Label={i18n("account.header.remaining-credit")}
            amount1={hasSurplusBalance ? -1 * account.used : account.used}
            amount1Label={
              hasSurplusBalance
                ? i18n("account.header.surplus-balance")
                : i18n("account.header.used-credit")
            }
            image={hasCard && brickId && <EcsterCard brickId={brickId} />}
          />
          <NotificationPanel
            id="account-overview-notifications"
            notifications={notifications}
          />
          <ResponsivePanel
            desktop={2}
            tablet={2}
            mobile={1}
            className="account-panel-content"
            verticalLines
          >
            <div>
              <TabletOrDesktop>
                {latestTransactions}
                <hr />
                {nextPaymentPanel}
              </TabletOrDesktop>
              <Mobile>
                <div className="mtb-5x">{nextPaymentPanel}</div>
              </Mobile>
            </div>
            <AccountLinksPanel
              account={account}
              accountCard={accountCard}
              hasInactiveCards={hasInactiveCards}
            />
          </ResponsivePanel>
        </PanelContent>
        {isSE() && (
          <PaymentDialog
            account={account}
            isOpen={isPaymentDialogOpen}
            onRequestClose={this.onPaymentDialogRequestClose}
          />
        )}
      </Panel>
    );
  }
}

AccountPanel.propTypes = {
  user: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  accountCard: PropTypes.object,
  bill: PropTypes.object,
  className: PropTypes.string,
  dismissGetAccountBillOverviewError: PropTypes.func.isRequired,
  dismissGetAccountTransactionsError: PropTypes.func.isRequired,
  extraCards: PropTypes.arrayOf(PropTypes.object),
  getAccountBillOverview: PropTypes.func.isRequired,
  getAccountCards: PropTypes.func.isRequired,
  getAccountBillOverviewError: PropTypes.array,
  getAccountTransactions: PropTypes.func.isRequired,
  getAccountTransactionsError: PropTypes.object,
  totalTransactions: PropTypes.number.isRequired,
  transactions: PropTypes.array,
};

AccountPanel.defaultProps = {
  accountCard: null,
  bill: {},
  className: "",
  extraCards: [],
  getAccountBillOverviewError: null,
  getAccountTransactionsError: {},
  transactions: [],
};

/* istanbul ignore next */
function mapStateToProps({ account }, ownProps) {
  const transactions = account.accountTransactions[ownProps.account.reference];

  return {
    accountCard: account.accountCards
      ? account.accountCards[ownProps.account.reference]
      : null,
    bill: account.accountBillOverview[ownProps.account.reference],
    extraCards: account.extraCards
      ? account.extraCards[ownProps.account.reference]
      : [],
    getAccountBillOverviewError: account.getAccountBillOverviewError,
    getAccountTransactionsError: account.getAccountTransactionsError,
    totalTransactions: transactions ? transactions.length : 0,
    transactions: transactions
      ? transactions.slice(0, defaultFilter.shortList)
      : undefined,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    getAccountTransactions: (customerId, reference, filter) =>
      dispatch(getAccountTransactions(customerId, reference, filter, true)),
    dismissGetAccountTransactionsError: () =>
      dispatch(dismissGetAccountTransactionsError()),
    getAccountBillOverview: (customerId, reference) =>
      dispatch(getAccountBillOverview(customerId, reference)),
    getAccountCards: (customerId, reference) =>
      dispatch(getAccountCards(customerId, reference)),
    dismissGetAccountBillOverviewError: () =>
      dispatch(dismissGetAccountBillOverviewError()),
  };
}

export { AccountPanel as Component };

export default connect(mapStateToProps, mapDispatchToProps)(AccountPanel);
