// noinspection JSValidateTypes

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { i18n } from '@ecster/i18n';
import { GeneralErrorMessagePanel, Message } from '@ecster/components';
import { reduxActionSucceeded, reduxActionFailed } from '@ecster/util';
import { disappointedFaceIcon } from '@ecster/icons/H40/blue';
import { thumbsUpFilledIcon } from '@ecster/icons/H40/green';

import AuthenticatedPageTemplate from '../common/templates/AuthenticatedPageTemplate';
import { getAccounts, dismissGetAccountsError } from './redux/actions';
import { getCustomer, getCustomerProperty } from '../customer/redux/actions';
import { getContracts } from '../contract/redux/actions';
import { getInvoices } from '../invoice/redux/actions';
import { setLoginStatsDone } from '../authentication/redux/actions';
import AccountPanel from './components/AccountPanel';
import AccountPanelTerminatedAccount from './components/AccountPanelTerminatedAccount';

import ContactInfoAlert from './components/ContactInfoAlert';
import GlobalNotificationsPanel from '../../common/GlobalNotificationsPanel';
import ContractOverviewPanel from '../contract/ContractOverviewPanel';

import InvoicePanelV1 from '../invoice/v1/InvoicePanelV1';
import { mapOneOffInvoices } from '../invoice/v1/mapInvoicesV1';

import { isSE, isFI } from '../../common/country';
import { emailIsMissing, phoneIsMissing } from './util';

import { OnboardingDialog } from '../common';
import { getCookie, setCookie } from '@ecster/cookies';
import { PaymentDialog } from './PaymentDialog';
import dayjs from 'dayjs';

const Wrapper = ({ children }) => (
    <AuthenticatedPageTemplate header={i18n('account.overview.header')}>{children}</AuthenticatedPageTemplate>
);

Wrapper.propTypes = {
    children: PropTypes.array.isRequired,
};

/**
 * Finds the first account in the given array that has a surplus of more than 100 "ören".
 * Returns the account object if only one account has a surplus, otherwise returns null.
 *
 * @param {Array} accounts - An array of account objects.
 * @returns {Object|null} - The account object with a surplus, null if  multiple accounts have a surplus and false if no account has a surplus.
 */
const checkAccountsForSurplus = accounts => {
    let result = false;

    if (isFI()) return result;

    const accountsWithSurplus = accounts.filter(account => account.used < -100);
    if (accountsWithSurplus.length === 1) {
        result = accountsWithSurplus[0];
    }

    return result;
};

const isBeforeMayFirst2024 = dayjs().isBefore('2024-05-01 00:00:00');
export class OverviewPage extends PureComponent {
    operationCount = 0;

    state = {
        getCustomerDone: false,
        getAccountsDone: false,
        getContractsDone: false,
        getInvoicesDone: false,
        allDataAvailable: false,
        paymentDialogClosed: getCookie('paymentDialogClosed') === 1,
    };

    componentDidMount() {
        const {
            person: { id },
            getAccounts,
            getCustomer,
            getContracts,
            getInvoices,
            getCustomerProperty,
        } = this.props;

        // scrollTopOnLocationChange doesn't trigger when logging in - so scroll to top
        window.scrollTo(0, 0);

        if (id) {
            getAccounts(id);
            if (isSE()) {
                getContracts(id);
                getCustomer(id);
                getCustomerProperty(id, 'SHOW_LOAN_PROTECTION');
                getInvoices(id);
            }
        }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { setLoginStatsDone } = this.props;

        const expectedOperationCount = isSE() ? 2 : 1;

        if (reduxActionSucceeded('getCustomer', this.props, nextProps)) {
            this.setState({ getCustomerDone: true });
        }

        if (reduxActionSucceeded('getAccounts', this.props, nextProps)) {
            this.setState({ getAccountsDone: true });
        }

        if (reduxActionSucceeded('getContracts', this.props, nextProps)) {
            this.setState({ getContractsDone: true });
            this.operationCountPlus1();
        } else if (reduxActionFailed('getContracts', this.props, nextProps)) {
            this.operationCountPlus1();
        }

        if (reduxActionSucceeded('getInvoices', this.props, nextProps)) {
            this.setState({ getInvoicesDone: true });
            this.operationCountPlus1();
        } else if (reduxActionFailed('getInvoices', this.props, nextProps)) {
            this.operationCountPlus1();
        }

        if (this.operationCount === expectedOperationCount) {
            setLoginStatsDone(true);
            this.setState({ allDataAvailable: true });
        }
    }

    componentWillUnmount() {
        window.location.search = '';
    }

    handleClosePaymentDialog = () => {
        this.setState({ paymentDialogClosed: true });

        // Set cookie to expire after 1 year
        setCookie('paymentDialogClosed', 1, { expires: 365 });
    };

    operationCountPlus1() {
        this.operationCount += 1;
    }

    render() {
        const {
            accountsActive,
            accountsTerminated,
            person,
            hasZeroAccounts,
            hasZeroContracts,
            contactInformation,
            dismissGetAccountsError,
            getAccountsError,
            getContractsError,
            invoices,
            contracts,
            showLoanProtection,
        } = this.props;
        const {
            getCustomerDone,
            getAccountsDone,
            getContractsDone,
            getInvoicesDone,
            allDataAvailable,
            paymentDialogClosed,
        } = this.state;

        // ACS and ECR calls fail
        if (getAccountsError && getContractsError) {
            return (
                <Wrapper>
                    <GeneralErrorMessagePanel
                        header={i18n('general.error.general.header')}
                        body={i18n('general.error.general.body')}
                        error={getAccountsError}
                        isFatal
                        dismissError={dismissGetAccountsError}
                    />
                </Wrapper>
            );
        }

        // ACS call fails
        const accountWarning = getAccountsError && (
            <Message
                blue
                whiteBg
                iconUrl={disappointedFaceIcon}
                header={i18n('common.warning.account-unavailable.header')}
                message={i18n('common.warning.account-unavailable.info')}
            />
        );

        // ECR call fails
        const contractWarning = getContractsError && (
            <Message
                blue
                whiteBg
                iconUrl={disappointedFaceIcon}
                header={i18n('common.warning.contract-unavailable.header')}
                message={i18n('common.warning.contract-unavailable.info')}
            />
        );

        const scriveSuccessMessage = /scrive-payment=ok/.exec(window.location.search) && (
            <Message
                className="mb-4x"
                green
                whiteBg
                iconUrl={thumbsUpFilledIcon}
                header={i18n('account.overview.payment-dialog.success.header')}
                message={i18n('account.overview.payment-dialog.success.text')}
            />
        );

        const activeAccounts =
            getAccountsDone &&
            accountsActive.map(account => <AccountPanel key={account.reference} account={account} user={person} />);

        const terminatedAccounts =
            getAccountsDone &&
            accountsTerminated.map(account => (
                <AccountPanelTerminatedAccount key={account.reference} account={account} user={person} />
            ));

        const showAlert =
            getCustomerDone &&
            !hasZeroAccounts &&
            (emailIsMissing(contactInformation) || phoneIsMissing(contactInformation));

        const getContractsByStatus = status =>
            getContractsDone &&
            contracts &&
            contracts
                .filter(contract => contract.status === status)
                // eslint-disable-next-line react/no-array-index-key
                .map((contract, index) => <ContractOverviewPanel contract={contract} key={index} />);

        const contactUsContracts = getContractsByStatus('CONTACT_US');
        const deniedContracts = getContractsByStatus('DENIED');
        const collectionContracts = getContractsByStatus('COLLECTION');
        const aipContracts = getContractsByStatus('AIP'); // lånelöfte
        const activeContracts = getContractsByStatus('ACTIVE');
        const finishedContracts = getContractsByStatus('FINISHED');

        const showPaymentDialog = checkAccountsForSurplus([...accountsActive, ...accountsTerminated]);

        return (
            <Wrapper>
                {scriveSuccessMessage}
                <GlobalNotificationsPanel />
                <div className="account-overview-page">
                    <ContactInfoAlert showAlert={showAlert} customerId={person.id} />

                    {getInvoicesDone && hasZeroAccounts && hasZeroContracts && (
                        <>
                            <InvoicePanelV1
                                invoices={mapOneOffInvoices(invoices, person.id)}
                                header={i18n('account.overview.invoices-header')}
                            />
                        </>
                    )}

                    {accountWarning}
                    {contractWarning}
                    {contactUsContracts}
                    {deniedContracts}
                    {collectionContracts}
                    {aipContracts}
                    {activeAccounts}
                    {activeContracts}
                    {terminatedAccounts}
                    {finishedContracts}
                </div>
                {isSE() && <OnboardingDialog customerId={person.id} showDialog={() => false} />}
                {showPaymentDialog && (
                    <PaymentDialog
                        onClose={this.handleClosePaymentDialog}
                        account={showPaymentDialog}
                        isOpen={!paymentDialogClosed}
                        onRequestClose={this.handleClosePaymentDialog}
                    />
                )}
            </Wrapper>
        );
    }
}

OverviewPage.propTypes = {
    accountsActive: PropTypes.array.isRequired,
    accountsTerminated: PropTypes.array,
    invoices: PropTypes.array,
    contracts: PropTypes.array,
    person: PropTypes.object.isRequired,
    contactInformation: PropTypes.object.isRequired,
    hasZeroAccounts: PropTypes.bool,
    hasZeroContracts: PropTypes.bool,

    showLoanProtection: PropTypes.bool.isRequired,

    // account actions
    getAccounts: PropTypes.func.isRequired,
    getAccountsError: PropTypes.shape({
        action: PropTypes.string.isRequired,
        message: PropTypes.object.isRequired,
    }),

    dismissGetAccountsError: PropTypes.func.isRequired,

    // invoice actions
    getInvoices: PropTypes.func.isRequired,
    getInvoicesPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getInvoicesError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    // contracts actions
    getContracts: PropTypes.func.isRequired,
    getContractsPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getContractsError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    // customer actions
    getCustomer: PropTypes.func.isRequired,
    getCustomerPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getCustomerError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    getCustomerProperty: PropTypes.func.isRequired,

    // GA
    setLoginStatsDone: PropTypes.func.isRequired,
    loginStatsDone: PropTypes.bool.isRequired,
};

OverviewPage.defaultProps = {
    accountsTerminated: [],
    invoices: null,
    contracts: null,
    hasZeroAccounts: false,
    hasZeroContracts: false,
    getAccountsError: null,
    getContractsError: null,
    getCustomerError: null,
    getInvoicesError: null,
};

/* istanbul ignore next */
function mapStateToProps({ account, authentication, customer, invoice, contract }) {
    return {
        accountsActive: account.accountsActive,
        accountsTerminated: account.accountsTerminated,
        invoices: invoice.invoices,
        contracts: contract.contracts,
        hasZeroAccounts: account.hasZeroAccounts,
        hasZeroContracts: contract.hasZeroContracts,
        person: authentication.person,
        contactInformation: customer.customer.contactInformation,

        getAccountsPending: account.getAccountsPending,
        getAccountsError: account.getAccountsError,

        getContractsPending: contract.getContractsPending,
        getContractsError: contract.getContractsError,

        getCustomerPending: customer.getCustomerPending,
        getCustomerError: customer.getCustomerError,

        getInvoicesPending: invoice.getInvoicesPending,
        getInvoicesError: invoice.getInvoicesError,

        showLoanProtection: customer.SHOW_LOAN_PROTECTION,

        // GA
        loginStatsDone: authentication.loginStatsDone,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        getAccounts: customerId => dispatch(getAccounts(customerId)),
        getInvoices: customerId => dispatch(getInvoices(customerId)),
        getContracts: customerId => dispatch(getContracts(customerId)),
        getCustomer: customerId => dispatch(getCustomer(customerId, true)),
        getCustomerProperty: (customerId, property) => dispatch(getCustomerProperty(customerId, property)),
        dismissGetAccountsError: () => dispatch(dismissGetAccountsError()),
        setLoginStatsDone: done => dispatch(setLoginStatsDone(done)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
