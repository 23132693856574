import React from 'react';
import PropTypes from 'prop-types';

import ReactSlider from 'react-slider';

import './Slider.scss';

interface SliderProps {
    /**
     * Slider min value
     */
    min: number;

    /**
     * Slider max value
     */
    max: number;

    /**
     * Slider value
     */
    value: number;

    /**
     * Function called when slider value changes
     */
    onChange: Function;

    /**
     * Function called *after* slider value has changed
     */
    onAfterChange?: Function;

    /**
     * Steps when sliding
     */
    step?: number;
}

const Slider = (props: SliderProps) => {
    // const classes = useStyles();

    return (
        <div>
            <ReactSlider className="ec-slider" {...props} thumbClassName="slider-thumb" trackClassName="slider-track" />
        </div>
    );
};

Slider.defaultProps = {
    step: 1,
    value: 0,
    onAfterChange: () => {},
};

export default Slider;
