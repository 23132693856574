import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { renderThisCountry } from '../../common/i18n/renderThisCountry';

import {
    LinkToAccountInvoice,
    LinkToAccountInvoices,
    LinkToAccountTerms,
    LinkToManageCards,
    LinkToTransactions,
} from './Links';

import './AccountLinksPanel.scss';

const className = 'arrow-links-panel';

export class AccountLinksPanel extends Component {
    // eslint-disable-next-line react/no-unused-class-component-methods
    renderSE() {
        const {
            account: { reference, loyaltiesAllowed, allowIncreaseLimit },
            accountCard,
        } = this.props;

        // Check if object is empty - if it is we have no card information.
        const hasCard = accountCard
            ? !(Object.keys(accountCard).length === 0 && accountCard.constructor === Object)
            : false;

        return (
            <div className={className}>
                <LinkToTransactions accountRef={reference} />
                <LinkToAccountInvoice accountRef={reference} />
                {hasCard && <LinkToManageCards accountRef={reference} allowIncreaseLimit={allowIncreaseLimit} />}
                <LinkToAccountTerms accountRef={reference} />
            </div>
        );
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    renderFI() {
        const {
            account: { reference },
        } = this.props;

        return (
            <div className={className}>
                <LinkToTransactions accountRef={reference} />
                <LinkToAccountInvoices accountRef={reference} />
                <LinkToAccountTerms accountRef={reference} />
            </div>
        );
    }

    render() {
        return renderThisCountry(this);
    }
}

AccountLinksPanel.propTypes = {
    account: PropTypes.object.isRequired,
    accountCard: PropTypes.object,
    hasInactiveCards: PropTypes.bool.isRequired,
};

AccountLinksPanel.defaultProps = {
    accountCard: {},
};
