import dayjs from 'dayjs';
import {
    OverviewPage,
    AccountTransactionsOverviewPage,
    AccountTerms,
    TerminateAccountPage,
    AccountInvoicePage,
    AccountInvoiceSpecPage,
    AccountInvoicesPage,
    TransferMoneyPage,
    CampaignPurchasesPage,
} from '.';

import { isFI } from '../../common/country';

const seRoutes = [];

const fiRoutes = isFI()
    ? [
          { path: ':accountRef/invoices', name: 'Account invoices', component: AccountInvoicesPage },
          { path: ':accountRef/transfer-money', name: 'Transfer money page', component: TransferMoneyPage },
          {
              path: ':accountRef/campaign-purchases',
              name: 'Campaign purchases page',
              component: CampaignPurchasesPage,
          },
      ]
    : [];

const routes = {
    path: 'account',
    name: 'Account',
    childRoutes: [
        ...seRoutes,
        ...fiRoutes,
        { path: 'overview', name: 'Account overview', component: OverviewPage },

        {
            path: ':accountRef/transactions',
            name: 'Account transactions overview',
            component: AccountTransactionsOverviewPage,
        },

        {
            path: ':accountRef/terms',
            name: 'Account terms',
            component: AccountTerms,
        },

        { path: ':accountRef/terminate', name: 'Terminate account', component: TerminateAccountPage },

        {
            path: ':accountRef/invoice/specification',
            name: 'Account invoice specification',
            component: AccountInvoiceSpecPage,
        },

        {
            path: ':accountRef/invoice',
            name: 'Account invoice',
            component: AccountInvoicePage,
        },
    ],
};

export default routes;
