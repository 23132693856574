import React from 'react';

import SendAMessage from './SendAMessage';
import { CallUs } from './CallUs';
import { CallUsComplaint } from './CallUsComplaint';

import './ContactUsInfoFI.scss';

const ContactUsInfoFI = () => {
    return (
        <div className="customer-contact-us-info">
            <CallUsComplaint />
            <SendAMessage />
            <CallUs />
        </div>
    );
};

ContactUsInfoFI.propTypes = {};

ContactUsInfoFI.defaultProps = {};

export default ContactUsInfoFI;
